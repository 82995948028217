// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: 'AIzaSyCwk8CIrR-i4jaebTt38zdJwvTbRk5sK2Q',
  authDomain: 'ditatus.se',
  projectId: 'ditatus-se',
  storageBucket: 'ditatus-se.appspot.com',
  messagingSenderId: '192121482193',
  appId: '1:192121482193:web:26a02897c06a794d504ef0',
  measurementId: 'G-V60Z3STJTB',
};

export default firebaseConfig;
