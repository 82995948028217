// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/logo_clean.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container[data-v-9d9a21ac] {\n  animation: 1.5s ease-out 0s 1 slideInFromBelow-9d9a21ac;\n}\n.logo[data-v-9d9a21ac] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: contain;\n  background-position: center;\n  width: 15rem;\n  height: 20rem;\n  position: relative;\n}\n@keyframes slideInFromBelow-9d9a21ac {\n0% {\n    transform: translateY(50%);\n    display: none;\n    opacity: 0;\n}\n1% {\n    display: block;\n    opacity: 0;\n}\n100% {\n    transform: translateY(0);\n    display: block;\n    opacity: 1;\n}\n}\n.p-button[data-v-9d9a21ac] {\n  color: #303841 !important;\n  font-size: 1rem;\n  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;\n}\n.p-button[data-v-9d9a21ac]:enabled:hover {\n  background: #e8e8e8 !important;\n  border-color: #e8e8e8 !important;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
