<script>
import Hero from '@/components/Hero.vue';

export default {
  name: 'App',
  components: {
    Hero,
  },
  beforeMount() {
    this.faviconListener();
  },
  methods: {
    faviconListener() {
      const dark = window.matchMedia('(prefers-color-scheme:dark)');
      dark.addListener((e) => {
        if (!e.matches) {
          return;
        }
        this.processTheme('dark');
      });
      if (dark.matches) {
        this.processTheme('dark');
      }
      const light = window.matchMedia('(prefers-color-scheme:light)');
      light.addListener((e) => {
        if (!e.matches) {
          return;
        }
        this.processTheme('light');
      });
      if (light.matches) {
        this.processTheme('light');
      }
    },
    processTheme(mode) {
      const link1 = document.createElement('link');
      link1.setAttribute('rel', 'favicon icon');
      const link2 = document.createElement('link');
      link2.setAttribute('rel', 'favicon icon');
      link2.setAttribute('sizes', '16x16');
      const link3 = document.createElement('link');
      link3.setAttribute('rel', 'favicon icon');
      link3.setAttribute('sizes', '32x32');
      const head = document.getElementById('appHead');
      head.appendChild(link1);
      head.appendChild(link2);
      head.appendChild(link3);
      this.changeTheme(link1, '[type="image/x-icon"]', mode);
      this.changeTheme(link2, '[sizes="16x16"]', mode);
      this.changeTheme(link3, '[sizes="32x32"]', mode);
    },
    changeTheme(link, selector, mode) {
      const source = document.querySelector('link[rel*="icon"]' + selector + '[media="(prefers-color-scheme:' + mode + ')"]');
      if (source === null) {
        return;
      }
      link.setAttribute('type', source.type);
      link.setAttribute('href', source.href);
    },
  },
};
</script>

<template>
  <div class="body-wrapper">
    <Hero />
  </div>
</template>

<style lang="scss">
html {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  min-width: 320px;
  background: #f3f3f3;
  color: #303841;
  font-family: var(--body-font-family);
  overflow: hidden;
}

a {
  text-decoration: none;
}

.body-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  width: 100%;
  min-width: 320px;
  min-height: 100vh;
}

</style>
